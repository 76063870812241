import {
	BehaviorSubject,
	combineLatest,
	delay,
	distinctUntilChanged,
	filter,
	firstValueFrom,
	map,
	Observable,
	Subject,
	takeUntil,
	tap,
} from 'rxjs';

import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	inject,
	OnDestroy,
	Output,
	signal,
	viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { AUTHORIZED_ROUTES_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import {
	AuthorizedRoutesServiceProvider,
	ExtendedRoute,
	ExtendedRouteData,
	Theme,
	VisColor,
	VisIcon,
} from '@zeiss/ng-vis-common/types';
import { TutorialTopics } from '@zeiss/ng-vis-tutorial/types';

interface ExtendedRouteFiltered extends ExtendedRoute {
	filterScore: number;
}

@Component({
	selector: 'vis-quick-nav',
	templateUrl: './quick-nav.component.html',
	styleUrls: ['./quick-nav.component.scss'],
})
export class QuickNavComponent implements AfterViewInit, OnDestroy {
	private translocoService = inject(TranslocoService);
	private router = inject(Router);
	routesService = inject<AuthorizedRoutesServiceProvider>(AUTHORIZED_ROUTES_SERVICE);

	private destroyed$ = new Subject<void>();
	private DIRECT_NAV_KEYS = ['enter', '1', '2', '3', '4', '5'];
	THEME = Theme;
	VisColor = VisColor;
	VisIcon = VisIcon;
	TUTORIAL_TOPIC = TutorialTopics;

	authorizedRoutesFiltered$: Observable<ExtendedRouteFiltered[]>;
	routesFilter$ = signal('');
	searchBarFocus$ = signal(false);
	@Output() searchShowResults$ = new BehaviorSubject(false);
	selectedIndex$ = signal(0);

	hoverSearch$ = signal(false);
	private searchBar = viewChild<ElementRef>('searchBar');

	@HostListener('window:keydown', ['$event'])
	private async handleKeyDown(event: KeyboardEvent) {
		if (this.toggleMenu(event) || (await this.scrollByKeyboard(event)) || (await this.routeByKeyboard(event)))
			event.preventDefault();
	}
	@HostListener('window:click', ['$event'])
	private async handleClick(_: MouseEvent) {
		// close menu if clicked outside
		if (!this.hoverSearch$()) this.loseSearchbarFocus();
	}

	constructor() {
		// filter and sort authorized routes to display them in the search menu
		this.authorizedRoutesFiltered$ = combineLatest([
			this.routesService.authorizedRoutes$.pipe(
				map((routes) =>
					routes
						.filter((x) => x.data?.showInMenu || x.data.routes.some((y) => (y.data.keywords ?? []).length > 0))
						.map((route) => ({
							...route,
							data: {
								...route.data,
								routes: route.data.routes.filter((x) => x.data?.showInMenu || (x.data.keywords ?? []).length > 0),
							},
						}))
						.flatMap((x) => x.data.routes)
				)
			),
			toObservable(this.routesFilter$),
		]).pipe(
			map(([routes, filter]) => {
				const retVal = routes
					.concat()
					.map((route) => ({
						...route,
						filterScore: this.filterPredicateRoute(route, filter),
					}))
					.filter((route) => route.filterScore > -1);

				retVal.sort((left, right) => {
					var scoreOrder = right.filterScore - left.filterScore;
					var titleOrder = this.translatedTitle(left.data).localeCompare(this.translatedTitle(right.data));
					return -scoreOrder || titleOrder;
				});

				return retVal;
			}),
			distinctUntilChanged(),
			tap((routes) => {
				if (routes.length > 0 && this.selectedIndex$() > routes.length - 1) this.selectedIndex$.set(routes.length - 1);
			})
		);

		// lose focus and close menu on nav end
		this.router.events
			.pipe(
				takeUntil(this.destroyed$),
				filter((event) => event instanceof NavigationEnd),
				delay(100),
				tap(() => {
					this.searchShowResults$.next(false);
					this.loseSearchbarFocus();
				})
			)
			.subscribe();

		// hide headerbar portal if menu is opened
		this.searchShowResults$.subscribe((shown) => {
			if (shown) document.querySelector('.vis-headerbar-portal')?.classList.add('vis-headerbar-portal-hidden');
			else document.querySelector('.vis-headerbar-portal')?.classList.remove('vis-headerbar-portal-hidden');
		});
	}

	async ngAfterViewInit() {
		// deactivate autocomplete in the nested inputfield
		const intVal = setInterval(() => {
			if (this.searchBarInputElement) {
				this.searchBarInputElement.autocomplete = 'off';
				clearInterval(intVal);
			}
		}, 500);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	private toggleMenu(event: KeyboardEvent) {
		const key = event.key.toLowerCase();

		switch (key) {
			case 'f1':
				if (this.searchShowResults$.getValue()) {
					this.loseSearchbarFocus();
				} else {
					this.searchBarFocus$.set(true);
					this.searchBarInputElement.select();
				}
				event.preventDefault();
				break;

			case 'escape':
				this.loseSearchbarFocus();
				break;

			default:
				return false;
		}

		return true;
	}

	private async scrollByKeyboard(event: KeyboardEvent) {
		const key = event.key.toLowerCase();

		if (
			!this.searchShowResults$.getValue() ||
			(key !== 'tab' && key !== 'arrowdown' && key !== 'arrowup' && key !== 'home' && key !== 'end')
		)
			return false;

		const routesFiltered = await firstValueFrom(this.authorizedRoutesFiltered$);
		if (routesFiltered.length < 1) return;

		let nextIndex = this.selectedIndex$();
		const resultCount = routesFiltered?.length ?? 0;

		if ((event.shiftKey && key === 'tab') || key === 'arrowup') {
			if (nextIndex === 0) nextIndex = resultCount;
			nextIndex--;
		} else if (key === 'tab' || key === 'arrowdown') {
			if (nextIndex >= resultCount - 1) nextIndex = -1;
			nextIndex++;
		} else if (key === 'home') {
			nextIndex = 0;
		} else if (key === 'end') {
			nextIndex = resultCount - 1;
		}

		this.selectedIndex$.set(nextIndex);
		document.getElementById('menu-' + this.selectedIndex$())?.scrollIntoView({ behavior: 'smooth', block: 'center' });

		return true;
	}

	private async routeByKeyboard(event: KeyboardEvent) {
		const key = event.key.toLowerCase();

		if (!this.searchShowResults$.getValue() || !this.DIRECT_NAV_KEYS.includes(key)) return false;

		let selectedIndex = this.selectedIndex$();
		if (key !== 'enter') selectedIndex = +key - 1;

		const url = await this.urlByMenuItemIndex(selectedIndex);
		this.router.navigateByUrl(url);

		return true;
	}

	async routeByIndex(selectedIndex: number) {
		const url = await this.urlByMenuItemIndex(selectedIndex);
		this.router.navigateByUrl(url);
	}

	private translatedTitle(routeData: ExtendedRouteData) {
		return routeData.title
			? `${this.translocoService.translate(routeData.absoluteTitlePrefix ?? '')} ${this.translocoService.translate(
					routeData.title
			  )}`
			: '';
	}

	loseSearchbarFocus() {
		this.searchBarFocus$.set(false);
		this.searchShowResults$.next(false);
		setTimeout(() => this.searchBar()?.nativeElement.blur());
	}

	private filterPredicateRoute(route: ExtendedRoute, filter: string) {
		let score = -1;

		if ((route.data.keywords ?? []).some((x) => x.toLowerCase().includes(filter.trim().toLowerCase()))) score = 1;
		else if (
			route.data.title &&
			this.translocoService.translate(route.data.title).toLowerCase().includes(filter.trim().toLowerCase())
		)
			score = 2;
		else if (
			route.data.absoluteTitlePrefix &&
			this.translocoService
				.translate(route.data.absoluteTitlePrefix)
				.toLowerCase()
				.includes(filter.trim().toLowerCase())
		)
			score = 3;
		else if (
			(route.data.absoluteUrl ?? route.data.url ?? route.path).toLowerCase().includes(filter.trim().toLowerCase())
		)
			score = 4;

		if (score > -1 && this.routesService.isBookmark(route.data.absoluteUrl ?? route.data.url ?? route.path)) score = 0;

		return score;
	}

	private async urlByMenuItemIndex(index: number) {
		const routesList = await firstValueFrom(this.authorizedRoutesFiltered$);
		const selectedRoute = routesList[index];
		if (!selectedRoute) return;

		const normalizedUrl = (selectedRoute.data.absoluteUrl ?? selectedRoute.data.url ?? selectedRoute.path)
			.trim()
			.split('/')
			.filter((url) => !!url)
			.join('/');

		return normalizedUrl;
	}

	private get searchBarInputElement(): HTMLInputElement | null {
		try {
			const el1 = this.searchBar()?.nativeElement.shadowRoot;
			const el2 = el1?.querySelector('zui-searchbar-input')?.shadowRoot;
			const el3 = el2?.querySelector('.searchbar-input-wrapper') as HTMLElement;
			const el4 = el3?.querySelector('.input-wrapper');
			const el5 = el4?.querySelector('input');
			return el5 as HTMLInputElement;
		} catch {
			return null;
		}
	}
}
