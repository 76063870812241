@if ((routesService.authorizedRoutes$ | async).length > 3) {
<zui-searchbar
	autocomplete="off"
	[visAutofocus]="searchBarFocus$()"
	[placeholder]="'vp.goTo' | transloco"
	[showSearchResults]="searchShowResults$ | async"
	(searchbar-input)="routesFilter$.set($any($event).detail.value)"
	[value]="routesFilter$()"
	(mouseover)="hoverSearch$.set(true)"
	(mouseleave)="hoverSearch$.set(false)"
	(searchbar-input-focused)="searchShowResults$.next(true)"
	(click)="searchShowResults$.next(true)"
	id="searchbar-jump-to"
	[visTutorial]="{
			topic: TUTORIAL_TOPIC.General,
			title: 'vp.tutorial.app.quickNav' | transloco,
			text: 'vp.tutorial.app.quickNavDesc' | transloco,
		}"
	#searchBar
>
	@if (authorizedRoutesFiltered$ | async; as routes) { @if (routes.length < 1) {
	<zui-menu-divider>{{ 'vp.nothingFoundFor' | transloco : { filter: routesFilter$() } }}</zui-menu-divider>
	} @else {
	<zui-menu-item
		*ngFor="let route of routes ?? []; index as i"
		(click)="this.selectedIndex$.set(i); routeByIndex(i)"
		[class.selected]="selectedIndex$() === i"
		[visTooltip]="route.data.absoluteUrl ?? route.data.url ?? route.path"
	>
		<div class="route-title-container">
			<div class="route-title-container">
				<vis-icon
					[class.selected]="selectedIndex$() === i"
					[svgIcon]="VisIcon.ARROW_FILLED_RIGHT"
					[color]="VisColor.INTERACTIVE"
					class="selected-icon"
				/>

				<div
					[class.bold]="selectedIndex$() === i"
					[class.nav-hint]="route.data.title"
					[innerHTML]="
						route.data.absoluteTitlePrefix ?? '' | transloco | visTextHighlight : routesFilter$() : THEME.light
					"
				></div>
				<div
					[class.bold]="selectedIndex$() === i"
					[innerHTML]="route.data.title ?? '' | transloco | visTextHighlight : routesFilter$() : THEME.light"
				></div>

				<vis-icon *ngIf="route.filterScore === 0" [svgIcon]="VisIcon.FAVORITE_FILLED" [color]="VisColor.WARNING" />
			</div>
			<div style="display: flex; gap: 4px">
				<div *ngIf="selectedIndex$() === i" class="nav-hint keystroke-character">⏎</div>
				<div *ngIf="selectedIndex$() === i && i < 5" class="nav-hint">{{ 'vp.or' | transloco }}</div>
				<div *ngIf="i < 5" class="nav-hint keystroke-character">{{ i + 1 }}</div>
			</div>
		</div>
		<div
			[class.selected]="selectedIndex$() === i"
			class="nav-hint keywords-container"
			[innerHTML]="(route.data.keywords ?? []).join(', ') | visTextHighlight : routesFilter$() : THEME.light"
		></div>
	</zui-menu-item>
	} }
</zui-searchbar>

<div
	*ngIf="(searchShowResults$ | async) !== true && routesFilter$().length < 1"
	class="nav-hint keystroke-character input-keystroke-icon"
>
	F1
</div>
}
