<vis-icon
	[class.event-active]="!!currentEvent"
	[visTooltip]="'vp.home' | transloco"
	[svgIcon]="VisIcon.ZEISS"
	class="app-logo"
	size="xl"
></vis-icon>

<div class="event-container" *ngIf="!!currentEvent">
	<img class="event-img" [attr.src]="'assets/images/yearly-event/' + currentEvent.img" />
	<div class="event-greeting">{{ currentEvent.greeting }}</div>
</div>
