<div class="app">
	<div class="tutorial-general-spot-wrapper">
		<div id="tutorial-general-spot"></div>
	</div>

	<vis-headerbar />

	<div class="main-container">
		<vis-menubar
			*ngIf="routerService.routeConfig.length > 0"
			[visTutorial]="{
				topic: tutorialTopics.General,
				title: 'vp.tutorial.app.welcome' | transloco,
				text: 'vp.tutorial.app.welcomeDesc' | transloco : { appName: app.NameFull },
				options: {
					hideFocus: true,
					order: -1000,
				},
			}"
		/>

		<div class="content" (scroll)="systemService.scrolled = $event">
			<vis-global-search></vis-global-search>
			<router-outlet #router="outlet" *ngIf="(authService.statusExtended | async)?.authenticated; else status" />

			<ng-template #status
				><vp-auth-state>
					<a style="padding-top: 2em; font-size: 1.3em" href="#" (click)="openFaqDialog($event)">{{
						'vp.accessHelp' | transloco
					}}</a>
				</vp-auth-state></ng-template
			>
		</div>
	</div>
</div>

<vis-toast />
