<zui-headerbar>
	<!-- App logo -->
	<a [routerLink]="ROUTE_PATH.root._" slot="icon" class="href-discrete" [visTooltip]="'vp.home' | transloco">
		<vis-event></vis-event>
	</a>

	<!-- App name -->
	<zui-headerbar-product-name
		class="cursor-pointer href-discrete"
		slot="productName"
		[visTooltip]="'vp.home' | transloco"
		[routerLink]="ROUTE_PATH.root._"
		*ngIf="
			((systemService.maxWidth | async) !== BREAKPOINT.mobile &&
				(systemService.maxWidth | async) !== BREAKPOINT.tablet) ||
			(routesService.isOnBase$ | async)
		"
		[attr.product-name]="APP.Name1"
		[attr.product-sub-name]="APP.Name2"
	></zui-headerbar-product-name>

	<!-- Breadcrumbs -->
	<div class="breadcrumbs" slot="productName">
		<span
			*ngFor="let routeData of breadcrumbs$ | async as filteredRoutes; index as i"
			[class.active-breadcrumb]="i === filteredRoutes.length - 1"
			[attr.index]="i"
		>
			<ng-container *ngIf="routeData.absoluteUrl && (routeData.url ?? '').length > 1">
				<vis-icon *ngIf="i > 0" size="s" inline [color]="VisColor.INTERACTIVE" [svgIcon]="VisIcon.ARROW_OUTLINE_RIGHT">
				</vis-icon>

				<a *ngIf="i < filteredRoutes.length - 1; else noHref" [routerLink]="routeData.absoluteUrl || routeData.url">
					{{ routeData.title ?? '' | transloco }}
				</a>

				<ng-template #noHref>
					<span>{{ routeData.title ?? '' | transloco }}</span>

					<span
						*ngIf="routeData.absoluteUrl.split('/').length > 1 && routeData.showInMenu"
						[visTooltip]="
							(routesService.isBookmark(routeData.absoluteUrl)
								? 'vp.tutorial.bookmark.removeSpecific'
								: 'vp.tutorial.bookmark.addSpecific'
							)
								| transloco
									: {
											titlePrefix: (routeData.absoluteTitlePrefix ?? '' | transloco),
											title: (routeData.title ?? '' | transloco)
									  }
						"
					>
						<vis-icon
							*ngIf="routesService.isBookmark(routeData.absoluteUrl)"
							[svgIcon]="VisIcon.FAVORITE_FILLED"
							[color]="VisColor.WARNING"
							[interactive]="true"
							size="s"
							(click)="routesService.toggleBookmark(routeData.absoluteUrl)"
						></vis-icon>

						<vis-icon
							*ngIf="!routesService.isBookmark(routeData.absoluteUrl)"
							size="s"
							(click)="routesService.toggleBookmark(routeData.absoluteUrl)"
							[interactive]="true"
							[svgIcon]="VisIcon.FAVORITE_OUTLINE"
							[visTutorial]="{
								topic: TUTORIAL_TOPIC.General,
								title: 'vp.tutorial.bookmark.add' | transloco,
								text:
									'vp.tutorial.bookmark.addSpecificByClick'
									| transloco
										: {
												titlePrefix: (routeData.absoluteTitlePrefix ?? '' | transloco),
												title: (routeData.title ?? '' | transloco)
										  },
								options: {
									order: -940,
								},
							}"
						></vis-icon>
					</span>
				</ng-template>
			</ng-container>
		</span>
		<vis-icon
			*ngIf="(routesService.isOnBase$ | async) !== true && (languageNotSupported$ | async)"
			class="language-warning cursor-help"
			[visTooltip]="
				'vp.warnings.languageNotSupported'
					| transloco : { defaultLanguage: APP.DefaultLanguage, currentLanguage: languageService.Language }
			"
			[svgIcon]="VisIcon.EWI_WARNING_TRIANGLE"
			[color]="VisColor.INTERACTIVE"
			[interactive]="true"
			size="m"
		></vis-icon>
	</div>

	<vis-quick-nav slot="searchbar" (searchShowResults$)="(searchShowResults$)" />

	<zui-searchbar slot="searchbar" style="display: none">
		<!--This needs to be here because otherwise the searchbar inside vis-quick-nav is hidden  -->
	</zui-searchbar>

	<!-- Tutorial -->
	<zui-headerbar-icon-button
		[visTutorial]="{
			topic: TUTORIAL_TOPIC.General,
			title: 'Tutorial',
			text: 'Click here to show up this tutorial.',
			options: {
				order: -990,
			},
		}"
		size="l"
		slot="iconButtons"
		visTooltip="Tutorial"
		[attr.emphasis]="(tutorial.state | async) === 'OPEN' ? 'selected' : 'default'"
		(click)="tutorial.Open()"
	>
		<vis-icon size="l" slot="icon" [svgIcon]="VisIcon.HELP_THIN"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- Logs -->
	<a
		slot="iconButtons"
		[visBusy]="routesService.isLoading(LOGS_ROUTE_PATH._)"
		style="position: relative"
		[routerLink]="LOGS_ROUTE_PATH._"
		#rlaLogs="routerLinkActive"
		routerLinkActive
		class="href-discrete"
	>
		<zui-headerbar-icon-button
			[visTutorial]="{
				topic: TUTORIAL_TOPIC.General,
				title: 'System logs',
				text: 'Click here to review what has happened when.',
				options: {
					order: -980,
				},
			}"
			size="l"
			slot="iconButtons"
			[visTooltip]="'vp.logs.title' | transloco"
			[attr.emphasis]="rlaLogs.isActive ? 'selected' : 'default'"
		>
			<vis-icon size="l" slot="icon" [svgIcon]="VisIcon.FILE_LOG"></vis-icon>
		</zui-headerbar-icon-button>
	</a>

	<!-- Tasks -->
	<ng-container *ngIf="(auth.status | async)?.authenticated">
		<a
			*vpProtect="{ rule: RULE.system_functions, required: ['view_tasks'] }"
			slot="iconButtons"
			[visBusy]="taskApi.Busy() || routesService.isLoading(TASKS_ROUTE_PATH._)"
			style="position: relative"
			[routerLink]="TASKS_ROUTE_PATH._"
			#rlaTasks="routerLinkActive"
			routerLinkActive
			class="href-discrete"
		>
			<zui-headerbar-notification-button
				[visTutorial]="{
					topic: TUTORIAL_TOPIC.General,
					title: 'Your tasks',
					text:
						'Click here to get an overview of your tasks.<br>' +
						(((task.openTasks$ | async)?.length ?? 0) > 0
							? 'You currently have open tasks pending. (red dot)'
							: 'A red dot indicates that there are tasks pending.'),
					options: {
						order: -970,
					},
				}"
				size="l"
				[visTooltip]="'vp.tasks.title' | transloco"
				[attr.emphasis]="rlaTasks.isActive ? 'selected' : 'default'"
			>
				<span slot="icon">
					<vis-icon size="l" [svgIcon]="VisIcon.BELL"></vis-icon>
					<vis-badge
						slot="stateDot"
						[value]="(task.openTasks$ | async)?.length"
						[options]="{
							absolute: true,
							top: 'var(--zui-gu)',
							right: 'var(--zui-gu)',
						}"
						[color]="VisColor.ERROR"
					></vis-badge>
				</span>
			</zui-headerbar-notification-button>
		</a>
	</ng-container>

	<!-- User settings -->
	<a
		[visBusy]="routesService.isLoading(SETTINGS_ROUTE_PATH._)"
		slot="userMenuButton"
		style="position: relative"
		[routerLink]="SETTINGS_ROUTE_PATH._"
		#rlaSettings="routerLinkActive"
		[routerLinkActive]="SETTINGS_ROUTE_PATH._"
		class="href-discrete"
	>
		<zui-user-menu-button
			[visTutorial]="{
				topic: TUTORIAL_TOPIC.General,
				title: 'User profile',
				text: 'Click here if you want to adjust your settings (e.g. change the <b>color theme or language</b>) or to <b>sign out</b>.',
				options: {
					order: -960,
				},
			}"
			*ngIf="auth.account"
			[visTooltip]="'vp.settings.title' | transloco"
			[attr.emphasis]="rlaSettings.isActive ? 'selected' : 'default'"
			[attr.user-name]="auth.account.firstName + ' ' + auth.account.lastName"
			[attr.user-role]="auth.account.organisationName | shorten : 21"
		>
			<zui-avatar
				slot="avatar"
				[attr.initials]="parseName(auth.account.firstName, auth.account.lastName).initials"
			></zui-avatar>
		</zui-user-menu-button>
	</a>

	<!-- Environment badge -->
	<div slot="icon" class="env-badge" *ngIf="environment !== ENVIRONMENT.PRD" [attr.env]="environment">
		{{ environment }}
	</div>

	<!-- Portal -->
	<ng-template [cdkPortalOutlet]="portalService.portal$ | async"></ng-template>
</zui-headerbar>
